import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThePosts = _resolveComponent("ThePosts")
  const _component_Scroller = _resolveComponent("Scroller")

  return (_openBlock(), _createBlock(_component_Scroller, {
    onBottomTouched: _ctx.loadMore,
    class: "fill p-16"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ThePosts, {
        content: _ctx.loaded,
        "no-pin": ""
      }, null, 8, ["content"])
    ]),
    _: 1
  }, 8, ["onBottomTouched"]))
}