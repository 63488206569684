import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThePostItem = _resolveComponent("ThePostItem")
  const _component_Line = _resolveComponent("Line")
  const _component_List = _resolveComponent("List")
  const _component_Holder = _resolveComponent("Holder")

  return (_openBlock(), _createBlock(_component_Holder, {
    content: $props.content,
    "skeleton-repeat": 2
  }, {
    default: _withCtx(() => [
      _createVNode(_component_List, {
        "list-data": $props.content,
        "pull-refresh": "",
        onRefresh: _cache[1] || (_cache[1] = done => _ctx.$emit('refresh', done)),
        identify: v => v.postId
      }, {
        default: _withCtx(({ item }) => [
          _createVNode(_component_ThePostItem, {
            post: item,
            "no-pin": $props.noPin,
            class: "mb-8"
          }, null, 8, ["post", "no-pin"]),
          _createVNode(_component_Line)
        ]),
        _: 1
      }, 8, ["list-data", "identify"])
    ]),
    _: 1
  }, 8, ["content"]))
}