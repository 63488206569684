
import Line from '@/components/Line.vue'
import ThePostItem from '@/modules/home/components/items/ThePostItem.vue'
import Holder from '@/provider/Holder.vue'
import List from '@/components/AsyncNullableList.vue'

export default {
  name: 'ThePosts',
  components: { Line, ThePostItem, List, Holder },
  emits: ['refresh'],
  props: {
    content: {
      type: Array,
      require: true,
    },
    noPin: {
      type: Boolean,
    },
  },
}
